<template lang="html">

  <div class="usluga">
    <div class="headUsluga">


  <router-link class="navbar-brand" to="/ksuslugeEng">Back </router-link>
    <h3>HR Professional Program</h3>
    <p>The HR Professional Program is a mentoring program designed so that HR employees (as well as beginners who want to build a career in the field of Human Resource Management) reach a higher level in their careers by transferring knowledge, experience and methodology by the help and mentorship of our consultants. During the program, the following topics are covered: HR impact on the organization, Selection and recruitment, Implementation of Performance Management, Talent Management, Internal communications, Resolving conflicts in teams, HR budgeting, Employee education and development strategy, Employer branding, as well as organizational structure and culture.</p>
    <h5>You need these services? Fill the form below:</h5>
  </div>

    <div class="card-body">


         <div class="form-group">
           <label for="numberOfEmployees">Number of employees for whom the services are required</label>
           <input
             class="form-control"
             type="text"
             placeholder=""
             name="numberOfEmployees"
             v-model="numberOfEmployees"
             id="numberOfEmployees"
             >
         </div>
         <div class="form-group">
           <label for="nameOfPosition">Name of workplace position for employees for whom the services are required<p style="display:inline;color:red">*</p></label>
           <input
             class="form-control"
             type="text"
             placeholder=""
             name="nameOfPosition"
             v-model="nameOfPosition"
             id="nameOfPosition"
             >
         </div>
         <div class="form-group">
           <label for="nameOfEmployee">Full name of employee/employees for whom the services are required (if there are more than one, separate names by a comma):<p style="display:inline;color:red">*</p></label>
           <input
             class="form-control"
             type="text"
             placeholder=""
             name="nameOfEmployee"
             v-model="nameOfEmployee"
             id="nameOfEmployee"
             >
         </div>
        <div class="form-group">
          <label for="companyName">Company name<p style="display:inline;color:red">*</p></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            name="companyName"
            v-model="companyName"
            id="companyName"
            >
        </div>
        <div class="form-group">
          <label for="contactName">Full name of contact person<p style="display:inline;color:red">*</p></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            name="contactName"
            v-model="contactName"
            id="contactName"
            >
        </div>
        <div class="form-group">
          <label for="contactNumber">Phone number<p style="display:inline;color:red">*</p></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            name="contactNumber"
            v-model="contactNumber"
            id="contactNumber"
            >
        </div>
        <div class="form-group">
          <label for="email">E-mail<p style="display:inline;color:red">*</p></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            name="email"
            v-model="email"
            id="email"
            >
        </div>



        <div class="validation">
          {{validation}}
        </div>
        <button class="btn btn-primary" @click="submit">Submit</button>
        &nbsp;&nbsp;&nbsp;&nbsp;



  </div>

  </div>

</template>

<script>
// eslint-disable-next-line no-unused-vars
import {mapActions,mapGetters} from 'vuex';
export default {

  name: 'HRProfessionalProgramEng',
  components: {

  },
  data(){
    return{
      companyName:"",
  contactName:"",
  contactNumber:"",
  email:"",
  numberOfEmployees :"",
  nameOfPosition:"",
  nameOfEmployee:"",
  validation:"",
  date:"",
    }
  },
  methods:{
  ...mapActions(['submitServiceAplication']),
  submit(){

    this.validation="";

    if(this.companyName==""){
      this.validation="Unesite naziv kompanije."
    }
    if(this.contactName==""){
      this.validation="Unesite ime i prezime kontakt osobe."
    }
    if(this.email==""){
      this.validation="Unesite email."
    }
    if(this.contactNumber==""){
      this.validation="Unesite kontakt telefon."
    }
    if(this.nameOfPosition==""){
      this.validation="Unesite naziv pozicije zaposlenog/zaposlenih."
    }
    if(this.nameOfEmployee==""){
      this.validation="Unesite ime i prezime zaposlenog/zaposlenih."
    }
    const d = new Date();
    let day = d.getDate();
    let month = d.getMonth()+1;
    let year = d.getFullYear();
    this.date= "Datum: "+day+"."+month+"."+year+".";


    let text = [];
    text.push("HR Professional Program");
    text.push(this.date);
    text.push("Broj zaposlenih za koje se vrši usluga: "+this.numberOfEmployees);
    text.push("Naziv pozicije zaposlenog za kojeg se vrši usluga: "+this.nameOfPosition);
    text.push("Ime i prezime zaposlenog/zaposlenih za koje se vrše usluge: "+this.nameOfEmployee);
    text.push("Naziv Kompanije: "+this.companyName);
    text.push("Ime i Prezime Kontakt Osobe: "+this.contactName);
    text.push("Email: "+this.email);
    text.push("Kontakt telefon: "+this.contactNumber  );


    let data ={
      formular:text
    }

    if(this.validation==""){
    this.submitServiceAplication(data);
    this.validation="Uspesno poslat formular."
    }
  },
},

  beforeCreate: function() {
        document.body.className = 'usluge';
  }
}
</script>

<style lang="css" scoped>
.usluga{
  position: absolute;
  top: auto;
  left: 10%;
  right: 10%;
  background-color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  margin-top: 15px;

}
.headUsluga{
  background-color: #77bbee;
  padding: 10px;
  border-top-left-radius:  10px;
  border-top-right-radius:  10px;
}
.card-body{
  background-color: #bbffff;
  border-bottom-left-radius:  10px;
  border-bottom-right-radius:  10px;
}
.validation{
  margin-top: 5px;
  background-color: #00aaaa;
}
</style>
